@import '../../../../local/templates/megamart_default/resources/sass/system';

@import '../../../../local/templates/megamart_default/resources/sass/layout/footer';
@import '../../../../local/templates/megamart_default/resources/sass/blocks/footer/menu';
@import '../../../../local/components/rsmm/megamart.socnet.links/templates/.default/style';


.icon-svg {
	&--mail {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-mail-view);
		}
	}

	&--phone {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-phone-view);
		}
	}

	&--map-pin {
		&:before {
			mask-image: url(/b24app/app/assets/images/icons.svg#svg-map-pin-view);
		}
	}
}

.l-footer {
	margin-top: 0;

	&--dark {
		.main-user-consent-request-announce {
			color: $extra;
		}
	}
}

.landing-block-sender-container {
	.bx-subscribe {
		.bx-input-group {
			flex-grow: 1;

			.bx-form-control {
				height: $input-height;
				padding: $input-padding-y $input-padding-x;
				font-family: $input-font-family;
				@include font-size($input-font-size);
				font-weight: $input-font-weight;
				line-height: $input-line-height;
				color: $input-color;
				background-color: $input-bg;
				border: $input-border-width solid $input-border-color;

				@include border-radius($input-border-radius, 0);

				@include box-shadow($input-box-shadow);
			}
		}
	}

	form {
		display: flex;
		flex-wrap: wrap;

		> .bx_subscribe_checkbox_container {
			order: 1;
		}

		.btn-subscribe {
			display: inline-block;
			font-family: $btn-font-family;
			font-weight: $btn-font-weight;
			color: $body-color;
			text-align: center;
			text-decoration: if($link-decoration == none, null, none);
			white-space: $btn-white-space;
			vertical-align: middle;
			user-select: none;
			background-color: transparent;
			border: none;
			@include button-size(0, 0, $btn-font-size, $btn-line-height, $btn-border-radius);
			@include transition($btn-transition);

			height: calc(#{$input-padding-y * 2 + $input-font-size * $input-line-height} + #{$input-border-width * 2});

			&:before {
				background-color: var(--primary);
			}

			> span {
				display: none;
			}
		}
	}
}